@font-face {
  font-family: "Cornerstone";
  /* src: local("Cornerstone"), url(./Cornerstone.ttf) format("ttf"); */
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
  src: url("./Cornerstone.ttf") format("ttf"),
    url("./Cornerstone.woff2") format("woff2"),
    url("./Cornerstone.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: "Cornerstone";
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
